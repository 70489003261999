import React from 'react';
import { Link } from 'react-router-dom';

const HomeLink = () => (
  <Link className="backBtn" to="/">
    Home
  </Link>
);

export default HomeLink;
