import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const remember = [
  '/remember/remember.png',
  '/remember/remember2.png',
  '/remember/remember3.png',
  '/remember/remember4.png',
];

const Remember = () => (
  <Container>
    {remember.map(card => (
      <img key={card} className={'mb-2'} src={card} alt={`card-${card}`}></img>
    ))}
    <HomeLink />
  </Container>
);

export default Remember;
