import React from 'react';
import PropTypes from 'prop-types';

const getGridGap = gap => (gap ? `gap-${gap}` : null);

const Container = ({ children, gap }) => (
  <div className={`grid p-4 w-screen ${getGridGap(gap)}`}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.object,
  gap: PropTypes.string,
};

export default Container;
