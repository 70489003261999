import React from 'react';
import TinderCard from 'react-tinder-card';
import HomeLink from '../components/HomeLink';

const cultureCardsImages = [...Array(58).keys()]
  .map(i => `/culturecards/cards${i + 1}.jpg`)
  .reverse();

const CultureCards = () => {
  const onSwipe = direction => {
    console.log('You swiped: ' + direction);
  };

  return (
    <div className="flex flex-col cultureCards mx-auto my-12 items-center">
      <h2>Swipe to see the next card</h2>
      {cultureCardsImages.map(card => {
        return (
          <TinderCard
            className={`absolute pt-8`}
            key={card}
            onSwipe={onSwipe}
            onCardLeftScreen={() => console.log('left')}
          >
            <div
              className="swipeCard p-12"
              style={{ backgroundImage: `url(${card})` }}
            />
          </TinderCard>
        );
      })}
      <HomeLink />
    </div>
  );
};

// Idea, render only two cards and change url on the upcoming one to not load all at once.

export default CultureCards;
