import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const Vision = () => {
  return (
    <Container>
      <img src="/vision/vision.png" alt="vision"></img>
      <HomeLink />
    </Container>
  );
};

export default Vision;
