import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const datesImages = [...Array(15).keys()].map(i => `/dates/date${i + 1}.png`);

const Dates = () => (
  <Container>
    {datesImages.map(card => (
      <img key={card} className="mb-2" src={card} alt={`date-${card}`}></img>
    ))}
    <HomeLink />
  </Container>
);

export default Dates;
