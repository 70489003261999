import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const cards = [
  '/cards/cards.png',
  '/cards/cards2.png',
  '/cards/cards3.png',
  '/cards/cards4.png',
  '/cards/cards5.png',
  '/cards/cards6.png',
  '/cards/cards7.png',
  '/cards/cards8.png',
  '/cards/cards9.png',
];

const Cards = () => (
  <Container>
    {cards.map(card => (
      <img key={card} src={card} alt={`card-${card}`}></img>
    ))}
    <HomeLink />
  </Container>
);

export default Cards;
