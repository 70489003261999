import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../components/Container';

const navigation = [
  {
    title: 'Psalm 91',
    route: '/psalm',
    color: '#ff895a',
    glow: '0 0px 10px rgb(255, 137, 90)',
  },
  {
    title: 'Discovery',
    route: '/Discovery',
    color: '#65d491',
  },
  { title: 'Vision', route: '/vision', color: '#ed6c64' },
  { title: 'Sunday Experience', route: '/experience', color: '#d4b657' },
  { title: 'Save the dates', route: '/dates', color: '#8ac1ea' },
  { title: '7 Cards', route: '/cards', color: '#68759f' },
  { title: 'Culture cards', route: '/culturecards', color: '#92cdc5' },
  {
    title: '3 things to remember',
    route: '/threethingsremember',
    color: '#f59c44',
  },
  { title: 'Social agreement', route: '/social', color: '#a3ab78' },
];

const Home = () => (
  <Container gap={2}>
    <div className="logo">
      <h1 className="pt-4 text-xl">Hillsong Berlin</h1>
      <h1 className="pb-6 text-xl">Team 2020</h1>
    </div>

    {navigation.map((link, i) => (
      <Link
        key={link + i}
        className="btn"
        style={{ backgroundColor: link.color, boxShadow: link.glow }}
        to={link.route}
      >
        {link.title}
      </Link>
    ))}
  </Container>
);

export default Home;
