import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Cards from './pages/Cards';
import Vision from './pages/Vision';
import Experience from './pages/Experience';
import Culture from './pages/Culture';
import Dates from './pages/Dates';
import CultureCards from './pages/CultureCards';
import Remember from './pages/Remember';
import Social from './pages/Social';
import Psalm from './pages/Psalm';
import Discovery from './pages/Discovery';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('loggedIn')
  );

  if (isLoggedIn) {
    return (
      <div className="wrapper flex">
        <Router>
          <Switch>
            <Route path="/Threethingsremember">
              <Remember />
            </Route>
            <Route path="/CultureCards">
              <CultureCards />
            </Route>
            <Route path="/Dates">
              <Dates />
            </Route>
            <Route path="/Culture">
              <Culture />
            </Route>
            <Route path="/Experience">
              <Experience />
            </Route>
            <Route path="/Vision">
              <Vision />
            </Route>
            <Route path="/Cards">
              <Cards />
            </Route>
            <Route path="/Social">
              <Social />
            </Route>
            <Route path="/Discovery">
              <Discovery />
            </Route>
            <Route path="/Psalm">
              <Psalm />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  } else {
    const password = prompt('Please enter the team password', '');
    if (password === 'team2020' || password === 'Team2020') {
      localStorage.setItem('loggedIn', true);
      setIsLoggedIn(true);
    } else {
      return <h1>Please refresh and provide the correct password</h1>;
    }
  }
}

export default App;
