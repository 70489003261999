import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const Discovery = () => {
  return (
    <Container>
      <img src="/discovery/1jesus.jpg" alt="jesus"></img>
      <img src="/discovery/2bible.jpg" alt="bible"></img>
      <img src="/discovery/3baptism.jpg" alt="baptism"></img>
      <HomeLink />
    </Container>
  );
};

export default Discovery;
