import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const Experience = () => (
  <Container>
    <img className="mb-8" src="/experience/header.png" alt="header"></img>
    <p className="reading-font antialiased leading-7 tracking-wide">
      Last Sunday a friend finally got me to go to Hillsong Berlin! Over the
      past few months, this friend had been continuously inviting me to
      &lsquo;just come and see for myself&rsquo;.
      <br />
      I was sort of interested, because I had begun to notice just how different
      my friend was - how their life was so colourful, whereas mine was so
      &lsquo;grey&rsquo; in comparison.
      <br />
      My friend is a remarkable person. And as I watched the way my friend
      treated and spoke about others (and about me), I guess I started getting a
      taste of what God must be like!
      <br />
      So I went along very nervous, with my preconceived ideas about what to
      expect.
      <br />
      As we arrived at the front door, some people met us, which was my first
      surprise, not only were they confident and friendly, they also looked so
      normal! What was really impressive was how everyone welcomed me - not
      really normal for people in Berlin who don&rsquo;t really know you - not
      only were they genuinely friendly, confident and smiling, but they also
      offered me a drink, and then introduced me to some other friendly people.
      <br />
      Even though I had never seen most of these people, I kind of felt
      strangely enough &ldquo;at home&rdquo;. I noticed other people arrived,
      even some parents - one of the team helped them with their kids to feel at
      ease and right at home.
      <br />
      <br />
      Wow - there was such a great buzz all around and I felt like I was at
      someone&rsquo;s birthday party or something and everyone was so excited. I
      couldn&rsquo;t help get a little excited myself as I thought about how
      personal and welcoming everyone was. I really started to feel quite
      special like I was their guest or a long lost uni friend or something.
      <br />
      Inside everything was neat and tidy and well ordered, right down to the
      tiniest detail. Quite impressive really, considering they just rent the
      building. The stage was really visual with screens and lights which
      initially surprised me - but then again, we are in Berlin, a very creative
      city!
      <br />
      I was really taken by the atmosphere - it was electric.
      <br />
      I couldn&rsquo;t help get a little excited. The music playing had a
      fantastic feel about it. Then it started - dynamic multimedia visuals,
      live music, a row of singers, a band.
      <br />
      Everybody started singing, clapping and even dancing along with the band.
      I guess these were the hymns that I was expecting!
      <br />
      It was amazing. After a few songs the tempo slowed and the atmosphere was
      incredible. Everyone seemed lost in the mood of the music, and I realised
      they were actually singing to God. I started to sense that there was
      something real about all this. Then it came to the speaker, which was
      another surprise. They actually made sense, and I could understand what
      was said. I began to see how I could apply the Bible to my ordinary,
      everyday life. The natural way that they spoke about Jesus and about being
      a Christian started to make me think this was real.
      <br />
      <br />
      I couldn&rsquo;t help myself begin to sense that there must be a God and
      that He was interested in my life and me.
      <br />
      Towards the end a question was asked which I knew was for me - did I want
      to turn from my grey life of living for myself and enter into a new life.
      I had begun to notice this &lsquo;new life&rsquo; in my friend and what I
      was experiencing right now was quite extraordinary.
      <br />
      This must be real, I thought, so I joined in and prayed and when
      everything had finished I went to my friend and asked them to help me get
      a bible that was mentioned.
      <br />
      Wow!! What I was experiencing was quite extraordinary. As I walked out I
      thought to myself...
    </p>
    <br />
    <h2 className="highlight text-base text-center md:text-lg mb-20">
      ‘TODAY MUST HAVE BEEN PREPARED JUST FOR ME.’
    </h2>
    <HomeLink />
  </Container>
);

export default Experience;
