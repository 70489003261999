import React from 'react';
import HomeLink from '../components/HomeLink';
import Container from '../components/Container';

const Psalm = () => {
  return (
    <Container>
      <img src="/Psalm/psalm.png" alt="psalm"></img>
      <HomeLink />
    </Container>
  );
};

export default Psalm;
